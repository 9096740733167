<template>
  <div class="flex min-h-screen w-full bg-gray-100 overflow-y-auto">
    <div class="flex w-full flex-col flex-grow justify-center align-middle">
      
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LoginView'
})
</script>
