import { createI18n } from 'vue-i18n'

export default createI18n({
  locale: 'fr',
  fallbackLocale: 'en',
  messages: {
    fr: require('./fr.json'),
    en: require('./en.json')
  }
})
